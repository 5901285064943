@import "reset";

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.react-datetime-picker__wrapper {
  border-radius: 4px;
}

.date-picker input {
  color: white;
}

.react-datetime-picker__calendar-button__icon {
  stroke: white;
}
